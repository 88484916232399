import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
//translations
import en from './translations/en.json'
import it from './translations/it.json'

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {en,it},
        ns: [],
        defaultNS: '',
        keySeparator: false,
        fallbackLng: 'en',
        debug: false,
        react: {
            useSuspense: false
        },
        supportedLngs: ['en', 'it'],
        nonExplicitWhitelist: true,
        interpolation: {
            escapeValue: false,
        },
        detection: {
            order: ['localStorage', 'navigator'],
            lookupLocalStorage: 'i18nextLng',
        }
    })

export default i18n
