import React from "react";
import dao from "../../../assets/images/26824.jpg"
import {useTranslation} from "react-i18next";

const Dao = () => {
    const {t,i18n:{language}} = useTranslation()

    return <div className="row align-items-center" id="dao">
        <div className="col-md-6 col-12">
            {language === 'it' ? <h2> {t("dao:dao_title_1")} {t("dao:dao_title_2")} {t("dao:dao_title_3")} <b>{t("dao:dao_title_4")}</b></h2> :
                <h2> {t("dao:dao_title_1")} <b>{t("dao:dao_title_2")}</b> {t("dao:dao_title_3")} {t("dao:dao_title_4")}</h2>
            }
            <div className="dash"/>
            <p className="mt-5">
                {t("dao:dao_p_1")}
            </p>
            <p className="mt-5">
                {t("dao:dao_p_2")}
            </p>
            <p className="mt-5">
                {t("dao:dao_p_3")}
                <span className="d-block fst-italic text-black">{t("dao:dao_p_4")}</span>
            </p>
        </div>
        <div className="col-md-6 col-12">
            <img src={dao} alt={dao} className="img-fluid"/>
        </div>
    </div>
}

export default Dao;
