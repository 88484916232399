import {createAlchemyWeb3} from "@alch/alchemy-web3";
import keccak256 from 'keccak256'
import {MerkleTree} from 'merkletreejs'
import {whitelist} from "./whitelist";
import {config} from "./contract.config";
import {ethers} from "ethers";
import {getMetaMask} from "../helpers/metamask";

const rinkebyURL = "https://rinkeby.etherscan.io"

// Interface with NFT contract
const web3 = createAlchemyWeb3(process.env.REACT_APP_ALCHEMY_RPC_URL)
const nftContract = new web3.eth.Contract(config.contractABI, config.contractAddress)

// Calculate merkle root from whitelist array
const leafNodes = whitelist.map(address => keccak256(address))
const merkleTree = new MerkleTree(leafNodes, keccak256, {sortPairs: true})
const root = merkleTree.getRoot()


export const NFTContractUtils = {
    getTotalMinted: async () => {
        return await nftContract.methods.totalSupply().call()
    },
    getMaxSupply: async () => {
        return await nftContract.methods.maxSupply().call()
    },
    getMaxTokensPerAddress: async () => {
        return await nftContract.methods.maxTokensPerAddress().call()
    },
    getMaxTokensPerTx: async () => {
        return await nftContract.methods.maxTokensTx().call()
    },
    isActive: async () => {
        return await nftContract.methods.isActive().call()
    },
    checkIfWalletInWhitelist: async (currentWalletAddress) => {
        const leaf = keccak256(currentWalletAddress)
        const proof = merkleTree.getHexProof(leaf)
        return await nftContract.methods.isWhitelisted(currentWalletAddress, proof).call()
    },
    getWalletBalance: async (currentWalletAddress) => {
        const web3API = window.ethereum
        //const currentWalletAddress = web3API.selectedAddress
        return await nftContract.methods.balanceOf(currentWalletAddress).call()
    },
    mint: async (mintAmount, currentWalletAddress) => {
        const web3API = getMetaMask()

        if(!currentWalletAddress){
            return {
                success: false,
                status: 'Please connect your wallet'
            }
        }

        const nonce = await web3.eth.getTransactionCount(
            currentWalletAddress,
            'latest'
        )

        const leaf = keccak256(currentWalletAddress)
        const proof = merkleTree.getHexProof(leaf)

        // Check max tokens user can own
        const maxTokens = await nftContract.methods.maxTokensPerAddress().call()
        // Check if already has >= 1 tokens
        const mintedTokens = await nftContract.methods.mintedTokens(currentWalletAddress).call()

        if(mintedTokens >= maxTokens) return

        // Check if in WL
        const isWL = await nftContract.methods.isWhitelisted(currentWalletAddress, proof).call()

        // Setup  ETH transaction
        const discountWLQty = !parseInt(mintedTokens) && isWL ? 1 : 0
        const whiteListAmount = mintAmount - discountWLQty

        // Lets convert unit price from wei to ether
        const mintPrice = ethers.utils.formatEther(
            await nftContract.methods.price().call()
        )

        let value = mintPrice * whiteListAmount
        const tx = {
            from: currentWalletAddress,
            to: config.contractAddress,
            data: nftContract.methods.mint(mintAmount, proof).encodeABI(),
            value: ethers.utils.parseEther(String(value)).toHexString()
        }

        console.log(tx);

        try{
            const txHash = await web3API.request({
                method: 'eth_sendTransaction',
                params: [tx]
            })
            return  {
                success: true,
                amount: mintAmount,
                status: (<a href={`${rinkebyURL}/tx/${txHash}`} target={'_blank'}>Check your transaction on Etherscan</a> )
            }
        }catch (err){
            return {
                success: false,
                status: "Something went wrong:" + err.message
            }
        }
    }
}
