export const KrominLogo = ({props}) => <svg {...props} width="167px" height="40px" viewBox="0 0 167 40">
    <defs>
        <linearGradient x1="28.8407093%" y1="71.4084694%" x2="94.6296871%" y2="5.65227239%" id="linearGradient-1">
            <stop stopColor="#B84FF8" offset="0%"/>
            <stop stopColor="#8227EE" offset="100%"/>
        </linearGradient>
        <linearGradient x1="55.1414696%" y1="44.8585304%" x2="100%" y2="0%" id="linearGradient-2">
            <stop stopColor="#B84FF8" offset="0%"/>
            <stop stopColor="#8227EE" offset="100%"/>
        </linearGradient>
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="SECTION/Footer" transform="translate(-130.000000, -68.000000)">
            <g id="EXTRA/Logo" transform="translate(130.000000, 68.000000)">
                <g id="Logo" transform="translate(0.000000, 0.000000)">
                    <g id="Logotipo" transform="translate(49.855072, 5.314010)" fill="#F9FAFB">
                        <path
                            d="M13.7085797,29.0057005 L7.3995942,20.5224155 L4.51708213,23.4909179 L4.51708213,28.8903382 C4.51708213,28.9970048 4.43051208,29.0835749 4.32384541,29.0835749 L0.193217391,29.0835749 C0.0863574879,29.0835749 -1.93236715e-05,28.9970048 -1.93236715e-05,28.8903382 L-1.93236715e-05,0.58173913 C-1.93236715e-05,0.475072464 0.0863574879,0.388502415 0.193217391,0.388502415 L4.32384541,0.388502415 C4.43051208,0.388502415 4.51708213,0.475072464 4.51708213,0.58173913 L4.51708213,18.3284058 L13.6229758,8.36724638 C13.6596908,8.32724638 13.7114783,8.30444444 13.7655845,8.30444444 L18.8334106,8.30444444 C19.0017198,8.30444444 19.0896425,8.50444444 18.9758261,8.62830918 L10.6260676,17.7258937 L19.2032657,28.7718841 C19.3018164,28.8986473 19.2113816,29.0835749 19.0506087,29.0835749 L13.8635556,29.0835749 C13.8024928,29.0835749 13.7449082,29.0547826 13.7085797,29.0057005"
                            id="Fill-1"/>
                        <path
                            d="M21.7670531,28.8903961 L21.7670531,8.49773913 C21.7670531,8.39107246 21.8536232,8.30450242 21.9602899,8.30450242 L26.0909179,8.30450242 C26.1977778,8.30450242 26.2841546,8.39107246 26.2841546,8.49773913 L26.2841546,11.3588019 C27.7954589,9.42798068 30.2074396,7.90682126 32.841256,7.83397101 C32.9494686,7.8312657 33.0385507,7.92015459 33.0385507,8.02856039 L33.0385507,12.0720386 C33.0385507,12.190686 32.9332367,12.2818937 32.815942,12.2621836 C32.4741063,12.2047923 32.0750725,12.1763865 31.6188406,12.1763865 C29.7258937,12.1763865 27.1877295,13.4672077 26.2841546,14.9296232 L26.2841546,28.8903961 C26.2841546,28.9970628 26.1977778,29.0836329 26.0909179,29.0836329 L21.9602899,29.0836329 C21.8536232,29.0836329 21.7670531,28.9970628 21.7670531,28.8903961"
                            id="Fill-3"/>
                        <path
                            d="M50.4623382,18.6724444 C50.4623382,15.0156329 48.3112271,11.7891594 44.4395362,11.7891594 C40.6105507,11.7891594 38.4594396,15.0156329 38.4594396,18.6724444 C38.4594396,22.3721546 40.6105507,25.5988213 44.4395362,25.5988213 C48.3112271,25.5988213 50.4623382,22.3721546 50.4623382,18.6724444 M33.7703575,18.6724444 C33.7703575,12.7356329 37.8571208,7.78838647 44.4395362,7.78838647 C51.1075556,7.78838647 55.1516135,12.7356329 55.1516135,18.6724444 C55.1516135,24.6523478 51.1075556,29.5997874 44.4395362,29.5997874 C37.8571208,29.5997874 33.7703575,24.6523478 33.7703575,18.6724444"
                            id="Fill-5"/>
                        <path
                            d="M82.9661063,29.0836329 C82.8594396,29.0836329 82.7728696,28.9970628 82.7728696,28.8903961 L82.7728696,15.531942 C82.7728696,13.2950338 81.7833043,11.7891401 79.3742222,11.7891401 C77.3521932,11.7891401 75.4592464,13.2088502 74.5558647,14.54257 L74.5558647,28.8903961 C74.5558647,28.9970628 74.4692947,29.0836329 74.362628,29.0836329 L70.232,29.0836329 C70.1251401,29.0836329 70.0387633,28.9970628 70.0387633,28.8903961 L70.0387633,15.531942 C70.0387633,13.2950338 69.0491981,11.7891401 66.5972174,11.7891401 C64.618087,11.7891401 62.7682319,13.2088502 61.8217585,14.5856618 L61.8217585,28.8903961 C61.8217585,28.9970628 61.7351884,29.0836329 61.6285217,29.0836329 L57.4977005,29.0836329 C57.3910338,29.0836329 57.3044638,28.9970628 57.3044638,28.8903961 L57.3044638,8.49773913 C57.3044638,8.39107246 57.3910338,8.30450242 57.4977005,8.30450242 L61.6285217,8.30450242 C61.7351884,8.30450242 61.8217585,8.39107246 61.8217585,8.49773913 L61.8217585,11.1437295 C62.6822415,9.85329469 65.4354783,7.78836715 68.5759614,7.78836715 C71.6735459,7.78836715 73.5664928,9.33696618 74.2546087,11.6171594 C75.4592464,9.72421256 78.2126763,7.78836715 81.3102609,7.78836715 C85.1390531,7.78836715 87.289971,9.85329469 87.289971,14.0693333 L87.289971,28.8903961 C87.289971,28.9970628 87.2035942,29.0836329 87.0967343,29.0836329 L82.9661063,29.0836329 Z"
                            id="Fill-7"/>
                        <path
                            d="M90.5398841,28.8903961 L90.5398841,8.49773913 C90.5398841,8.39107246 90.6264541,8.30450242 90.7331208,8.30450242 L94.8637488,8.30450242 C94.9704155,8.30450242 95.0569855,8.39107246 95.0569855,8.49773913 L95.0569855,28.8903961 C95.0569855,28.9970628 94.9704155,29.0836329 94.8637488,29.0836329 L90.7331208,29.0836329 C90.6264541,29.0836329 90.5398841,28.9970628 90.5398841,28.8903961 M89.980657,2.96981643 C89.980657,1.42141063 91.271285,0.173681159 92.7769855,0.173681159 C94.3255845,0.173681159 95.573314,1.42141063 95.573314,2.96981643 C95.573314,4.5186087 94.3255845,5.76614493 92.7769855,5.76614493 C91.271285,5.76614493 89.980657,4.5186087 89.980657,2.96981643"
                            id="Fill-9"/>
                        <path
                            d="M112.525662,29.0836329 C112.418995,29.0836329 112.332425,28.9970628 112.332425,28.8903961 L112.332425,16.0913623 C112.332425,12.8646957 110.697836,11.7891401 108.159478,11.7891401 C105.836386,11.7891401 103.857449,13.1657585 102.824986,14.5856618 L102.824986,28.8903961 C102.824986,28.9970628 102.738415,29.0836329 102.631749,29.0836329 L98.5009275,29.0836329 C98.3942609,29.0836329 98.3076908,28.9970628 98.3076908,28.8903961 L98.3076908,8.49773913 C98.3076908,8.39107246 98.3942609,8.30450242 98.5009275,8.30450242 L102.631749,8.30450242 C102.738415,8.30450242 102.824986,8.39107246 102.824986,8.49773913 L102.824986,11.1437295 C104.201604,9.50894686 106.869043,7.78836715 110.138415,7.78836715 C114.612618,7.78836715 116.84972,10.1974493 116.84972,14.4565797 L116.84972,28.8903961 C116.84972,28.9970628 116.76315,29.0836329 116.656483,29.0836329 L112.525662,29.0836329 Z"
                            id="Fill-11"/>
                    </g>
                    <g id="Marchio" transform="translate(0.000000, 0.096618)">
                        <path
                            d="M17.8056039,17.902029 L0.849082126,34.8585507 C-0.282898551,35.9905314 -0.282898551,37.8258937 0.849082126,38.9576812 C1.41487923,39.5236715 2.15671498,39.8067633 2.89855072,39.8067633 C3.64038647,39.8067633 4.38202899,39.5236715 4.94821256,38.9576812 L21.9047343,22.0011594 C23.0365217,20.869372 23.0365217,19.0340097 21.9047343,17.902029 C21.338744,17.3362319 20.5969082,17.0531401 19.8550725,17.0531401 C19.1132367,17.0531401 18.371401,17.3362319 17.8056039,17.902029"
                            id="Fill-15" fill="url(#linearGradient-1)"/>
                        <path
                            d="M23.7681159,0.0966183575 C22.167343,0.0966183575 20.8695652,1.39439614 20.8695652,2.99516908 C20.8695652,4.59594203 22.167343,5.89371981 23.7681159,5.89371981 L33.9130435,5.89371981 L33.9130435,29.9105314 L10.1656039,6.16289855 C9.62183575,5.61932367 8.88463768,5.31400966 8.11594203,5.31400966 L2.89855072,5.31400966 C1.29777778,5.31400966 0,6.61178744 0,8.21256039 C0,9.81333333 1.29777778,11.1111111 2.89855072,11.1111111 L6.91536232,11.1111111 L34.7621256,38.9576812 C35.3165217,39.5122705 36.0577778,39.8069565 36.8121739,39.8067633 C37.1855072,39.8067633 37.5623188,39.734686 37.9207729,39.5862802 C39.0038647,39.1373913 39.7101449,38.0807729 39.7101449,36.9082126 L39.7101449,2.99516908 C39.7101449,1.39439614 38.4123671,0.0966183575 36.8115942,0.0966183575 L23.7681159,0.0966183575 Z"
                            id="Fill-18" fill="url(#linearGradient-2)"/>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>

export const FoodIcon = ({props}) => <svg {...props} height="48" width="48">
    <path
        d="M4 20.1V18.1Q4 12.65 9.25 9.325Q14.5 6 24 6Q33.5 6 38.75 9.325Q44 12.65 44 18.1V20.1ZM7.1 17.1H40.9Q40.75 13.65 36.075 11.325Q31.4 9 24 9Q16.6 9 11.875 11.325Q7.15 13.65 7.1 17.1ZM4 28.9V25.9Q5.65 25.9 6.875 24.8Q8.1 23.7 10.8 23.7Q13.5 23.7 14.375 24.8Q15.25 25.9 17.3 25.9Q19.35 25.9 20.325 24.8Q21.3 23.7 24 23.7Q26.7 23.7 27.675 24.8Q28.65 25.9 30.7 25.9Q32.75 25.9 33.625 24.8Q34.5 23.7 37.2 23.7Q39.9 23.7 41.125 24.8Q42.35 25.9 44 25.9V28.9Q41.3 28.9 40.275 27.8Q39.25 26.7 37.2 26.7Q35.15 26.7 34.275 27.8Q33.4 28.9 30.7 28.9Q28 28.9 27.025 27.8Q26.05 26.7 24 26.7Q21.95 26.7 20.975 27.8Q20 28.9 17.3 28.9Q14.6 28.9 13.725 27.8Q12.85 26.7 10.8 26.7Q8.75 26.7 7.725 27.8Q6.7 28.9 4 28.9ZM7 42Q5.8 42 4.9 41.1Q4 40.2 4 39V32.6H44V39Q44 40.2 43.1 41.1Q42.2 42 41 42ZM7 39H41Q41 39 41 39Q41 39 41 39V35.6H7V39Q7 39 7 39Q7 39 7 39ZM7 35.6Q7 35.6 7 35.6Q7 35.6 7 35.6H41Q41 35.6 41 35.6Q41 35.6 41 35.6ZM7.1 17.1Q7.15 17.1 11.875 17.1Q16.6 17.1 24 17.1Q31.4 17.1 36.075 17.1Q40.75 17.1 40.9 17.1Z"/>
</svg>

export const PartyIcon = ({props}) => <svg {...props} height="48" width="48">
    <path
        d="M4 44 13.3 18 30.15 34.65ZM9.05 38.95 24.75 33.35 14.6 23.15ZM27.85 25.75 26.15 24.05 38.15 12.05Q39.75 10.45 42.2 10.425Q44.65 10.4 46.25 12L47.1 12.85L45.4 14.55L44.45 13.6Q43.5 12.65 42.25 12.625Q41 12.6 40 13.6ZM19.9 18 18.2 16.3 19.7 14.8Q20.85 13.65 20.775 12.175Q20.7 10.7 19.7 9.7L18.3 8.3L20 6.6L21.3 7.9Q23.05 9.65 23.025 12.275Q23 14.9 21.25 16.65ZM23.95 21.85 22.25 20.15 29.85 12.55Q30.8 11.6 30.775 10.125Q30.75 8.65 29.8 7.7L26.75 4.65L28.45 2.95L31.6 6.1Q33.15 7.7 33.2 10.125Q33.25 12.55 31.65 14.15ZM31.85 29.8 30.15 28.1 32.5 25.75Q34.25 24 36.7 23.95Q39.15 23.9 40.9 25.65L43.45 28.2L41.75 29.9L39.15 27.3Q38 26.15 36.75 26.15Q35.5 26.15 34.35 27.3ZM9.05 38.95Z"/>
</svg>

export const LearnIcon = ({props}) => <svg {...props} height="48" width="48">
    <path
        d="M24 40Q21.6 38.1 18.8 37.05Q16 36 13 36Q10.9 36 8.875 36.55Q6.85 37.1 5 38.1Q3.95 38.65 2.975 38.05Q2 37.45 2 36.3V12.2Q2 11.65 2.275 11.15Q2.55 10.65 3.1 10.4Q5.4 9.2 7.9 8.6Q10.4 8 13 8Q15.9 8 18.675 8.75Q21.45 9.5 24 11V36.3Q26.55 34.65 29.35 33.825Q32.15 33 35 33Q36.8 33 38.925 33.35Q41.05 33.7 43 34.8Q43 34.8 43 34.8Q43 34.8 43 34.8V9.55Q43.5 9.75 43.975 9.95Q44.45 10.15 44.9 10.4Q45.4 10.7 45.7 11.175Q46 11.65 46 12.2V36.3Q46 37.45 45.025 38.05Q44.05 38.65 43 38.1Q41.15 37.1 39.125 36.55Q37.1 36 35 36Q32 36 29.2 37.05Q26.4 38.1 24 40ZM27 31.65V14L40 1V20.35ZM21 34.8V12.85Q19.3 11.9 17.05 11.45Q14.8 11 13 11Q10.65 11 8.625 11.5Q6.6 12 5 12.8Q5 12.8 5 12.8Q5 12.8 5 12.8V34.8Q5 34.8 5 34.8Q5 34.8 5 34.8Q6.75 33.95 8.775 33.475Q10.8 33 13.05 33Q15.25 33 17.25 33.475Q19.25 33.95 21 34.8ZM21 34.8Q21 34.8 21 34.8Q21 34.8 21 34.8Q21 34.8 21 34.8Q21 34.8 21 34.8Q21 34.8 21 34.8Q21 34.8 21 34.8V12.85Q21 12.85 21 12.85Q21 12.85 21 12.85Q21 12.85 21 12.85Q21 12.85 21 12.85Q21 12.85 21 12.85Q21 12.85 21 12.85Z"/>
</svg>

export const GiftIcon = ({props}) => <svg {...props} height="48" width="48">
    <path
        d="M7 34.15V39Q7 39 7 39Q7 39 7 39H41Q41 39 41 39Q41 39 41 39V34.15ZM7 12H13.25Q13 11.55 12.85 10.875Q12.7 10.2 12.7 9.65Q12.7 7.3 14.35 5.65Q16 4 18.3 4Q19.85 4 21.15 4.775Q22.45 5.55 23.2 6.75L24.05 8.05L24.85 6.75Q25.65 5.5 26.9 4.75Q28.15 4 29.6 4Q32 4 33.65 5.625Q35.3 7.25 35.3 9.65Q35.3 10.2 35.175 10.75Q35.05 11.3 34.8 12H41Q42.2 12 43.1 12.9Q44 13.8 44 15V39Q44 40.2 43.1 41.1Q42.2 42 41 42H7Q5.8 42 4.9 41.1Q4 40.2 4 39V15Q4 13.8 4.9 12.9Q5.8 12 7 12ZM7 28.8H41V15Q41 15 41 15Q41 15 41 15H28.55L33.55 21.95L31.15 23.75L24 13.85L16.85 23.75L14.45 21.95L19.45 15H7Q7 15 7 15Q7 15 7 15ZM18.35 12.3Q19.45 12.3 20.225 11.525Q21 10.75 21 9.65Q21 8.55 20.225 7.775Q19.45 7 18.35 7Q17.25 7 16.475 7.775Q15.7 8.55 15.7 9.65Q15.7 10.75 16.475 11.525Q17.25 12.3 18.35 12.3ZM29.6 12.3Q30.75 12.3 31.525 11.525Q32.3 10.75 32.3 9.65Q32.3 8.55 31.525 7.775Q30.75 7 29.6 7Q28.55 7 27.775 7.775Q27 8.55 27 9.65Q27 10.75 27.775 11.525Q28.55 12.3 29.6 12.3Z"/>
</svg>

export const MerchIcon = ({props}) => <svg {...props} height="48" width="48">
    <path
        d="M39 44H9Q7.8 44 6.9 43.1Q6 42.2 6 41V14.5Q6 13.3 6.9 12.4Q7.8 11.5 9 11.5H14.5Q14.5 7.55 17.15 4.775Q19.8 2 23.75 2Q27.7 2 30.6 4.775Q33.5 7.55 33.5 11.5H39Q40.2 11.5 41.1 12.4Q42 13.3 42 14.5V41Q42 42.2 41.1 43.1Q40.2 44 39 44ZM17.5 11.5H30.5Q30.5 8.75 28.625 6.875Q26.75 5 24 5Q21.25 5 19.375 6.875Q17.5 8.75 17.5 11.5ZM39 41Q39 41 39 41Q39 41 39 41V14.5Q39 14.5 39 14.5Q39 14.5 39 14.5H9Q9 14.5 9 14.5Q9 14.5 9 14.5V41Q9 41 9 41Q9 41 9 41ZM24 26.5Q27.95 26.5 30.85 23.6Q33.75 20.7 33.75 16.75H30.75Q30.75 19.5 28.75 21.5Q26.75 23.5 24 23.5Q21.25 23.5 19.25 21.5Q17.25 19.5 17.25 16.75H14.25Q14.25 20.7 17.15 23.6Q20.05 26.5 24 26.5ZM9 14.5Q9 14.5 9 14.5Q9 14.5 9 14.5V41Q9 41 9 41Q9 41 9 41Q9 41 9 41Q9 41 9 41V14.5Q9 14.5 9 14.5Q9 14.5 9 14.5Z"/>
</svg>

export const LockIcon = ({props}) => <svg {...props} height="48" width="48">
    <path
        d="M14.5 11.5Q14.5 7.55 17.275 4.775Q20.05 2 24 2Q27.95 2 30.725 4.775Q33.5 7.55 33.5 11.5V16.3H37Q38.25 16.3 39.125 17.175Q40 18.05 40 19.3V41Q40 42.25 39.125 43.125Q38.25 44 37 44H11Q9.75 44 8.875 43.125Q8 42.25 8 41V19.3Q8 18.05 8.875 17.175Q9.75 16.3 11 16.3H30.5V11.5Q30.5 8.8 28.6 6.9Q26.7 5 24 5Q21.3 5 19.4 6.9Q17.5 8.8 17.5 11.5ZM11 41H37Q37 41 37 41Q37 41 37 41V19.3Q37 19.3 37 19.3Q37 19.3 37 19.3H11Q11 19.3 11 19.3Q11 19.3 11 19.3V41Q11 41 11 41Q11 41 11 41ZM24 26.3Q22.4 26.3 21.275 27.525Q20.15 28.75 20.15 30.25Q20.15 31.8 21.275 32.9Q22.4 34 24 34Q25.6 34 26.725 32.9Q27.85 31.8 27.85 30.25Q27.85 28.75 26.725 27.525Q25.6 26.3 24 26.3ZM11 19.3Q11 19.3 11 19.3Q11 19.3 11 19.3V41Q11 41 11 41Q11 41 11 41Q11 41 11 41Q11 41 11 41V19.3Q11 19.3 11 19.3Q11 19.3 11 19.3Z"/>
</svg>

export const WalletIcon = ({props}) => <svg {...props} viewBox="0 0 22 20">
    <path
        d="M19.8 4.444V2.222C19.8.997 18.813 0 17.6 0H3.3C1.48 0 0 1.496 0 3.333v13.334C0 19.112 1.973 20 3.3 20h16.5c1.213 0 2.2-.997 2.2-2.222V6.667c0-1.226-.987-2.223-2.2-2.223zM3.3 2.222h14.3v2.222H3.3a1.096 1.096 0 01-.747-.342 1.118 1.118 0 010-1.537c.196-.207.464-.33.747-.343zm16.5 15.556H3.313c-.508-.014-1.113-.217-1.113-1.111V6.46c.345.126.712.206 1.1.206h16.5v11.11z"
    />
</svg>

export const DoneIcon = ({props}) => <svg  {...props} height="24" width="24">
    <path d="M9.55 18 3.85 12.3 5.275 10.875 9.55 15.15 18.725 5.975 20.15 7.4Z"/>
</svg>

export const CalendarIcon = ({props}) => <svg {...props} height="40" width="40">
    <path
        d="M14.792 27.208Q13.083 27.208 11.896 26.021Q10.708 24.833 10.708 23.125Q10.708 21.417 11.896 20.229Q13.083 19.042 14.792 19.042Q16.542 19.042 17.708 20.229Q18.875 21.417 18.875 23.125Q18.875 24.875 17.688 26.042Q16.5 27.208 14.792 27.208ZM7.792 36.667Q6.667 36.667 5.833 35.833Q5 35 5 33.875V8.875Q5 7.75 5.833 6.938Q6.667 6.125 7.792 6.125H10.125V3.333H13.042V6.125H26.958V3.333H29.875V6.125H32.208Q33.333 6.125 34.167 6.938Q35 7.75 35 8.875V33.875Q35 35 34.167 35.833Q33.333 36.667 32.208 36.667ZM7.792 33.875H32.208Q32.208 33.875 32.208 33.875Q32.208 33.875 32.208 33.875V16.375H7.792V33.875Q7.792 33.875 7.792 33.875Q7.792 33.875 7.792 33.875ZM7.792 13.625H32.208V8.875Q32.208 8.875 32.208 8.875Q32.208 8.875 32.208 8.875H7.792Q7.792 8.875 7.792 8.875Q7.792 8.875 7.792 8.875ZM7.792 13.625V8.875Q7.792 8.875 7.792 8.875Q7.792 8.875 7.792 8.875Q7.792 8.875 7.792 8.875Q7.792 8.875 7.792 8.875V13.625Z"/>
</svg>

export const ArrowIcon = (props) => <svg {...props} height="24" width="24">
    <path d="M12 15.375 6 9.375 7.4 7.975 12 12.575 16.6 7.975 18 9.375Z"/>
</svg>