import React from 'react';
import Footer from "../components/footer";
import {useDispatch, useSelector} from "react-redux";
import {
    selectAlertMessage,
    selectIsEthChainSelected,
    selectIsMetaMaskInstalled, selectWalletAddress,
    toggleAlert
} from "../store/slices/app";
import Toast from "../components/toast";
import Overlay from "../components/overlay";
import {useLocation} from "react-router-dom";

const Layout = ({children, ...rest}) => {
    const dispatch = useDispatch();
    const isEthChainSelected = useSelector(selectIsEthChainSelected)
    const isMetaMaskInstalled = useSelector(selectIsMetaMaskInstalled)
    const walletAddress = useSelector(selectWalletAddress)
    const {
        variant: alertVariant,
        message: alertMessage,
        title: alertTitle,
        visible: alertVisbile,
    } = useSelector(selectAlertMessage)

    const location = useLocation()
    
    return <div>
        <Toast message={alertMessage} title={alertTitle} visible={alertVisbile} variant={alertVariant}  dismissCallback={() =>
            dispatch(toggleAlert({ visible: false }))
        }/>
        {
            location.pathname === '/mint' &&
                <>
                    {!isMetaMaskInstalled && <Overlay><span>Please install MetaMask to continue...</span></Overlay>}
                    {isMetaMaskInstalled && walletAddress && !isEthChainSelected && <Overlay><span>Please select ETH chain to continue...</span></Overlay>}
                </>
        }
        {children}
       <Footer/>
    </div>

}

export default Layout;
