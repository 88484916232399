import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/home";
import Mint from "./pages/mint";
import {useEffect} from "react";

function App() {

    useEffect(() => {

    }, [])

    return (<BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="mint" element={<Mint/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
