import React, {useState} from 'react';
import Layout from "../../layout";
import Benefit from "./benefit/Benefit";
import Dao from "./dao/Dao";
import About from "./about/About";
import Header from "../../components/header";
import Faq from "./faq/Faq";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next"
import Countdown from 'react-countdown';

const MINT_DATE = "Thu Jun 30 2022 10:40:00 GMT+0200"

const Home = () => {
    const [isMintActive,setIsMintActive] = useState(new Date(Date.now()) > new Date( MINT_DATE ))
    const {t} = useTranslation()

    // Renderer callback with condition
    const renderer = ({days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <span className={"countdown"}> {t("infos:mint_text_2")}</span>;
        } else {
            // Render a countdown
            return <span className={"countdown"}>{t("infos:mint_text_1")} <b>{days}{t("infos:days")}</b> <b>{hours}h {minutes}min {seconds}sec</b></span>;
        }
    };

    return <Layout>
        <div className={"dark-hero"}>
            <Header/>
            <div className="container h-100">
                <div className="row h-100">
                    <div className="col-12 align-items-center justify-content-center d-flex flex-column">
                        <h1>JOIN THE <br/> <b>REVOLUTION</b><span>.</span></h1>
                        <Link className={`mint-btn mt-5 ${!isMintActive && "disabled-mint-btn"}`} to={isMintActive ? "/mint" : "/"}> Mint </Link>
                        <Countdown date={new Date( MINT_DATE )} renderer={renderer} onComplete={() => setIsMintActive(true)}/>
                    </div>
                    <div className={"basic-infos-mobile d-md-none d-block"}>
                        <div className={"d-flex align-items-center justify-content-between"}>
                            <div className="single-info d-flex flex-column text-center">
                                <span className="mb-1">{t('infos:supply')}</span>
                                <span style={{fontSize:18}}> <b>69</b></span>
                            </div>
                            <div className="single-info d-flex flex-column text-center">
                                <span className="mb-1">{t('infos:violet')}</span>
                                <span style={{fontSize:18}}> <b>61</b> </span>
                            </div>
                            <div className="single-info d-flex flex-column text-center">
                                <span className="mb-1">{t('infos:gold')}</span>
                                <span style={{fontSize:18}}>  <b>8</b> </span>
                            </div>
                        </div>
                        <div className={"d-flex align-items-center justify-content-between mt-4"}>
                            <div className="single-info d-flex flex-column text-center">
                                <span className="mb-1">{t('infos:wl_mint')}</span>
                                <span style={{fontSize:18}}>  <b>free (1 max)</b> </span>
                            </div>
                            <div className="single-info d-flex flex-column text-center">
                                <span className="mb-1">{t('infos:public_mint')}</span>
                                <span style={{fontSize:18}}>  <b>0.0069eth</b> </span>
                            </div>
                        </div>
                        <div className="d-flex text-center mt-4">
                            <p className="text-center nb">{t("infos:nb")}</p>
                        </div>
                    </div>
                    <div className={"basic-infos d-md-flex d-none"}>
                        <div className="d-flex justify-content-around w-100">
                            <div className="single-info d-flex flex-column text-center">
                                <span className="mb-1">{t('infos:supply')}</span>
                                <span style={{fontSize:18}}> <b>69</b></span>
                            </div>
                            <div className="single-info d-flex flex-column text-center">
                                <span className="mb-1">{t('infos:violet')}</span>
                                <span style={{fontSize:18}}> <b>61</b> </span>
                            </div>
                            <div className="single-info d-flex flex-column text-center">
                                <span className="mb-1">{t('infos:gold')}</span>
                                <span style={{fontSize:18}}>  <b>8</b> </span>
                            </div>
                            <div className="single-info d-flex flex-column text-center">
                                <span className="mb-1">{t('infos:wl_mint')}</span>
                                <span style={{fontSize:18}}>  <b>free (1 max)</b> </span>
                            </div>
                            <div className="single-info d-flex flex-column text-center">
                                <span className="mb-1">{t('infos:public_mint')}</span>
                                <span style={{fontSize:18}}>  <b>0.0069eth</b> </span>
                            </div>
                        </div>
                        <p className="text-center nb mt-3">{t("infos:nb")}</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="section-padding">
            <div className="container">
               <About/>
            </div>
        </div>
        <div className={"dark-section section-padding"}>
            <div className="container">
                <div className="row">
                   <Benefit/>
                </div>
            </div>
        </div>
        <div className="section-padding">
            <div className="container">
                <Dao/>
            </div>
        </div>
        <div className="section-padding">
            <div className="container">
                <Faq/>
            </div>
        </div>
    </Layout>

}

export default Home;
