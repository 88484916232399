export const whitelist = [
    "0xe0CEd7EC1ffb31CdB4b11170017014670ace21Db",
    "0x6bcD548b386aCe2dE3ddBDC518C4994583a9A8a1",
    "0xA9a5C71050650b0DdEB7582CEB18acD42AfE38E6",
    "0x4924ca4E1A0f226779A44524110cF971e5d0D01c",
    "0x07bF144bE1BAda0d3a48f3382353098aAAbe8C87",
    "0x12CA627b55417EC27377c9d022154feA01e13709",
    "0x7Ce2a0e623FD723318CBA09702E99211A8116C3A",
    "0x1fcFE6CD5885B2c74868f0CD795473972dF8e373",
    "0x9b769A4feF5cE881C49615B6b0221E6d8ee0FcF0",
    "0x7FAE2A854B5549050b3B53819f0354558ADE25B9",
    "0xBA0EB5CD48C83D3dE9877Af4E619fcB59f784C4a",
    "0xb158A91d6fe04A79e4C4782cbd15Ed20605aC195",
    "0x06C7A2D2d04CadBAb61EEd02CDafE4D7b5eF8BEF",
    "0x19aC9282C52117E56EBBCBeF3D317A02bc548b1e",
    "0x2856a014B54749B35a412261FA591242935382b2",
    "0xe1555ee5aF5a0eD7f71a20558b80E378cc90Bc60",
]
