import React from "react";
import {CalendarIcon} from "../../../assets/icons/icons";
import "./about.css";
import nft from "../../../assets/images/viola.png"
import {Trans, useTranslation} from "react-i18next";

const About = () => {
    const {t} = useTranslation()

    return <>
        <div className="row mb-5 align-items-center" id="about">
            <div className="col-md-6 col-12">
                <h2>{t('about:collection_title')} <b>{t('about:collection_title_2')}</b></h2>
                <div className="dash"/>
                <p className="mt-5">
                    {t('about:collection_p_1')}
                </p>
                <p className="mt-5">
                    <Trans
                        i18nKey="about:collection_p_2"
                        components={{
                            link1: <a href={"https://opensea.io/collection/kromin-1m"} target={"_blank"} title="1 Million Limited" />,
                        }}
                    />

                </p>
                <p className="mt-5">
                    {t('about:collection_p_3')}
                </p>
            </div>
            <div className="col-md-6 col-12 d-flex justify-content-end">
                <img src={nft} alt={nft} className="img-fluid" width={500}/>
            </div>
        </div>
        <div className="row justify-content-between align-items-start pt-md-5 pt-3">
            <div className="col-md-6 col-12 order-md-1 order-2">
                <div className={"event"}>
                    <span>
                        <CalendarIcon/>
                        <span> {t('about:workshop_1_date')}</span>
                    </span>
                    <p>{t('about:workshop_1_desc')}</p>
                </div>
                <div className={"event"}>
                   <span>
                        <CalendarIcon/>
                        <span>{t('about:workshop_2_date')}</span>
                    </span>
                    <p> {t('about:workshop_2_desc')} </p>
                </div>
                <div className={"event"}>
                    <span>
                        <CalendarIcon/>
                        <span>{t('about:workshop_3_date')}</span>
                    </span>
                    <p> {t('about:workshop_3_desc')}</p>
                </div>
                <div className={"event"}>
                   <span>
                        <CalendarIcon/>
                        <span>{t('about:workshop_4_date')}</span>
                    </span>
                    <p> {t('about:workshop_4_desc')} </p>
                </div>
            </div>
            <div className="col-md-5 col-12 order-md-2 order-1 mb-md-0 mb-5">
                <h2>{t('about:workshop_title')} <b>{t('about:workshop_title_2')} </b></h2>
                <div className="dash"/>
                <p className="mt-5">{t('about:workshop_p_2')}</p>
            </div>
        </div>
    </>
}

export default About;
