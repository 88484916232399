import React, {useState} from "react";
import {ArrowIcon} from "../../assets/icons/icons";
import "./Accordion.css"

const Accordion = ({title, desc}) => {
    const [isOpen, setIsOpen] = useState(false)

    return <div className="accordion" id="accordionFaq">
            <div className="accordion">
                <div className="accordion-header d-flex justify-content-between align-items-center" onClick={() => setIsOpen(!isOpen)}>
                    <h5> {title} </h5>
                    <ArrowIcon className={isOpen ? "open" : "closed"}/>
                </div>

                {isOpen && <div className="accordion-body">
                    {desc}
                </div>}
            </div>
        </div>
}

export default Accordion;
