import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Layout from "../../layout";
import Header from "../../components/header";
import srcVideo from "../../assets/videos/viola 2.mp4";
import "./Mint.css"
import {NFTContractUtils} from "../../contract/interact";
import {
    selectWalletAddress,
    setAlertMessage,
} from "../../store/slices/app";
import {useDispatch, useSelector} from "react-redux";
import Spinner from "../../components/spinner";
import ReactCanvasConfetti from "react-canvas-confetti";
import {config} from "../../contract/contract.config";
import {useTranslation} from "react-i18next";
import {getWalletBalance} from "../../helpers/metamask";

const canvasStyles = {
    position: "fixed",
    pointerEvents: "none",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0
};

const Mint = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation()
    const [quantity, setQuantity] = useState(1);
    const [maxSupply, setMaxSupply] = useState(0);
    const [maxWalletSupply, setMaxWalletSupply] = useState(0);
    const [maxTokensTx, setMaxTokensTx] = useState(0);
    const [currentWalletSupply, setCurrentWalletSupply] = useState(0);
    const [totalMinted, setTotalMinted] = useState(0);
    const [status, setStatus] = useState(false);
    const [completedMint, setCompletedMint] = useState(0);
    const [isMinting, setIsMinting] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isWL, setIsWL] = useState(false);
    const [currentBalance, setCurrentBalance] = useState(0);
    const walletAddress = useSelector(selectWalletAddress)
    const residualQty = maxWalletSupply - currentWalletSupply
    const minQtyReached = (quantity - 1) < 1
    const maxQtyReached = (quantity + 1) > residualQty


    useEffect(
        () => {
            initGeneral()
        },
        []
    )

    useEffect(
        () => {
             init()
        },
        [walletAddress]
    )

    useEffect(
        () => {
            console.log('maxWalletSupply', maxWalletSupply)
            console.log('maxTokensTx', maxTokensTx)

        },
        [maxWalletSupply,maxTokensTx]
    )
    useEffect(
        () => {
            console.log('currentWalletSupply', currentWalletSupply)
            console.log('residual', residualQty)
            console.log('maxQtyReached', maxQtyReached)
            console.log('currentBalance', currentBalance)
        },
        [currentWalletSupply, residualQty, maxQtyReached, currentBalance]
    )

    const actualPrice = useMemo(() => {
        const discountWLQty = !currentWalletSupply && isWL ? 1 : 0
        return (quantity - discountWLQty) * config.price * 100
    }, [walletAddress, isWL, quantity, currentWalletSupply])

    const notEnoughBalance = currentBalance < actualPrice

    const init = async () => {
        try{
            if(walletAddress){
                setCurrentWalletSupply(parseInt(await NFTContractUtils.getWalletBalance(walletAddress)))
                setIsWL(await NFTContractUtils.checkIfWalletInWhitelist(walletAddress))
                setCurrentBalance(parseFloat(await getWalletBalance(walletAddress)))
            }else{
                setCurrentWalletSupply(0)
                setIsWL(false)
                setCurrentBalance(0)
            }

        }catch(err){
            dispatch(setAlertMessage({title:"Error", message: err.message}))
        }
    }

    const initGeneral = async () => {
        try{
            setMaxSupply(await NFTContractUtils.getMaxSupply())
            setTotalMinted(await NFTContractUtils.getTotalMinted())
            setIsActive(await NFTContractUtils.isActive())
            setMaxWalletSupply(await NFTContractUtils.getMaxTokensPerAddress())
            setMaxTokensTx(await NFTContractUtils.getMaxTokensPerTx())
        }catch(err){
            dispatch(setAlertMessage({title:"Error", message: err.message}))
        }
    }

    const onUpdateQuantity = (newQuantity) => {
        if(newQuantity > residualQty) {
            dispatch(setAlertMessage({title:"Error", message:"Max supply per wallet reached!"}))
            return
        }
        if(newQuantity > maxTokensTx) {
            dispatch(setAlertMessage({title:"Error", message:`You cannot mint more than ${maxTokensTx} in a single transaction!`}))
            return
        }
        setQuantity(newQuantity > 0  ? newQuantity : 1)
    }

    const onMint = async () => {

        if(!isActive) {
            dispatch(setAlertMessage({title:"Error", message:"Mint is currently inactive."}))
            return
        }
        if(!walletAddress) {
            dispatch(setAlertMessage({title:"Error", message:"Please connect a wallet!"}))
            return
        }
        if(notEnoughBalance){
            dispatch(setAlertMessage({title:"Error", message:"You don’t have enough ETH for the minting in your wallet!"}))
            return
        }
        try{
            setIsMinting(true)
            const {success, status, amount} = await NFTContractUtils.mint(quantity, walletAddress)
            dispatch(setAlertMessage({title: success ? 'Success' : 'Error', message: status, variant: success ? 'success' : 'fail'}))
            setStatus(success)
            setCompletedMint(amount)
            // Update mint stats
            initGeneral()
        }catch(err){
            dispatch(setAlertMessage({title:"Error", message: err.message}))
        }finally{
            setIsMinting(false)
        }
    }

    // Confetti
    const getInstance = useCallback((instance) => {
        refAnimationInstance.current = instance;
    }, []);
    const makeShot = useCallback((particleRatio, opts) => {
        refAnimationInstance.current &&
        refAnimationInstance.current({
            ...opts,
            origin: { y: 0.7 },
            particleCount: Math.floor(200 * particleRatio)
        });
    }, []);

    const fire = useCallback(() => {
        makeShot(0.25, {
            spread: 26,
            startVelocity: 55
        });

        makeShot(0.2, {
            spread: 60
        });

        makeShot(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8
        });

        makeShot(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2
        });

        makeShot(0.1, {
            spread: 120,
            startVelocity: 45
        });
    }, [makeShot]);
    const refAnimationInstance = useRef(null);

    useEffect(
        () => {
            if(status){
                fire()
            }
        },
        [status]
    )

    return <Layout>
        <div className={"dark-hero"} id={"mint"}>
            <Header isHome={false}/>
            <div className="container h-100">
                {
                    isMinting && <Spinner overlayFullscreen={true} />
                }
                <div className="row h-100">
                    <div className="col-12 align-items-center justify-content-center d-flex flex-column">
                        <div className="minting-info">
                            <p>NFT {t("mint:minted")}: <b>{totalMinted} / {maxSupply}</b></p>
                        </div>
                        {status ?
                            <div className="success">
                                <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
                                <h2 className={'pop-outin'}>Horray!</h2>
                                <h4 className={'animate-charcter'}>
                                    {t("mint:mint_success")}
                                    <div>{completedMint}</div>
                                    Kromin NFT!
                                </h4>
                                <button className={`mint-btn mt-2${!isActive && ' disabled-mint-btn'}`}
                                        onClick={() => window.location.reload()}> {t("mint:mint_again")}
                                </button>
                            </div>
                            :
                            <>
                                <div className="nft-prev">
                                    <div className="video" dangerouslySetInnerHTML={{
                                        __html: `
                                       <video width="360" height="360"
                                         loop
                                         muted
                                         autoplay
                                         playsinline
                                         webkit-playsinline
                                         src="${srcVideo}""
                                       />,
                                     `
                                    }}>
                                    </div>
                                </div>
                                <div className="quantity d-flex align-items-center justify-content-between">
                                    <button disabled={minQtyReached} className={`${minQtyReached && ' disabled-mint-btn'}`}
                                            onClick={() => onUpdateQuantity(quantity - 1)}> -
                                    </button>
                                    <span> {quantity}</span>
                                    <button disabled={maxQtyReached} className={`${maxQtyReached && ' disabled-mint-btn'}`}
                                            onClick={() => onUpdateQuantity(quantity + 1)}> +
                                    </button>
                                </div>
                                <div className={'price'}>({actualPrice} ETH)</div>
                                <button disabled={!isActive || notEnoughBalance} className={`mint-btn mt-2${(!isActive || notEnoughBalance) && ' disabled-mint-btn'}`}
                                        onClick={() => onMint()}>{t("mint:mint")}
                                </button>
                                {walletAddress && notEnoughBalance && <span className={'error-message'}>{t("mint:not_enough_balance")}</span>}
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    </Layout>

}

export default Mint;
