import React from 'react'

const getSpinnerStyle = ({overlayFullscreen, size}) => ({
    root: {
        zIndex: 14,
        width: size,
        height: 'auto',
        animation: 'rotate 2s linear infinite',
    },
    center: {
        position: overlayFullscreen ? 'fixed' : 'absolute',
        left: '50%',
        marginLeft: -size / 2,
        top: '50%',
        marginTop: -size / 2,
    },

    overlay: {
        zIndex: 13,
        position: overlayFullscreen ? 'fixed' : 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
    }
})
const Spinner = ({
    className,
    size = 100,
    color = 'rgb(255 165 2 / 98%)',
    strokeLineCap = 'square',
    overlay = true,
    overlayFullscreen = false,
    center = true,
    ...props
}) => {

    const spinnerStyle = getSpinnerStyle({overlayFullscreen, size})
    return (
        <>
            {overlay && <div style={spinnerStyle.overlay} />}
            <svg
                style={{
                    ...spinnerStyle.root,
                    ...(center && spinnerStyle.center)
                }}
                viewBox="0 0 50 50"
                {...props}
            >
                <circle
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    stroke={color}
                    strokeWidth="3"
                    strokeLinecap={strokeLineCap}
                    style={{
                        animation: 'dash 1.5s ease-in-out infinite'
                    }}
                />
            </svg>
        </>
    )
}

export default Spinner
