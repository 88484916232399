import React from 'react';
import "./beneditCard.css";

const BenefitItem = ({icon,title,desc}) => {

    return <div className="benefit-card">
        <div className="benefit-title">
            {icon} <h5>{title}</h5>
        </div>
        <p>{desc}</p>
    </div>

}

export default BenefitItem;
