import React, {useEffect, useState} from 'react';

const getToastStyle = ({variant}) => ({
    toastWrapper: {
        zIndex: 11,
        top: '10%'
    },
    toastContainer: {
        border: 0,
        borderLeft: `3px solid ${variant === "fail" ? "#ff0034" : "#20FF90"}`,
        backgroundColor: '#22292F',
        borderRadius: 0
    },
    toastHeader: {
        backgroundColor: '#22292F',
        color: variant === "fail" ? "#ff0034" : "#20FF90",
        borderBottom: '1px solid rgb(47 57 59)'
    },
    toastBody: {
        backgroundColor: '#22292F',
        zIndex: 11,
        color: '#fff'
    }
})

const Toast = ({variant = "fail",message,title,visible,dismissTimeout = 5000,dismissCallback }) => {

    useEffect(() => {
        if (dismissTimeout && visible) {
            const timeoutId = setTimeout(() => {
                closeHandler()
            }, +dismissTimeout)
            return () => {
                clearTimeout(timeoutId)
            }
        }
    }, [visible])

    const closeHandler = () => {
        dismissCallback && dismissCallback()
    }

    const toastStyle = getToastStyle({variant})

    return <div className="position-fixed end-0 p-3" style={toastStyle.toastWrapper}>
            <div id="liveToast" className={visible ? "toast show" : "toast hide"}
                 style={toastStyle.toastContainer}
                 role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-header" style={toastStyle.toastHeader}>
                    {title}
                </div>
                <div className="toast-body" style={toastStyle.toastBody}>
                    {message}
                </div>
            </div>
        </div>
}

export default Toast;
