import React from 'react';
import {DoneIcon, FoodIcon, GiftIcon, LearnIcon, LockIcon, MerchIcon, PartyIcon} from "../../../assets/icons/icons";
import BenefitItem from "../../../components/benefitItem";
import {useTranslation} from "react-i18next";

const Benefit = () => {
    const {t} = useTranslation()

    return <div id="benefit">
        <div className="col-12">
            <h2><b>Benefits</b></h2>
            <div className="dash"/>
        </div>

        <div className="row justify-content-between mt-5">
            <div className="col-md-5 col-12">
                <BenefitItem icon={<GiftIcon/>}
                             title={t('benefit:benefit_title_1')}
                             desc={t('benefit:benefit_desc_1')}/>
            </div>
            <div className="col-md-5 col-12">
                <BenefitItem icon={<PartyIcon/>}
                             title={t('benefit:benefit_title_2')}
                             desc={t('benefit:benefit_desc_2')}/>
            </div>
            <div className="col-md-5 col-12">
                <BenefitItem icon={<PartyIcon/>}
                             title={t('benefit:benefit_title_3')}
                             desc={t('benefit:benefit_desc_3')}/>
            </div>
            <div className="col-md-5 col-12">
                <BenefitItem icon={<LearnIcon/>}
                             title={t('benefit:benefit_title_4')}
                             desc={t('benefit:benefit_desc_4')}/>
            </div>
            <div className="col-md-5 col-12">
                <BenefitItem icon={<LearnIcon/>}
                             title={t('benefit:benefit_title_5')}
                             desc={t('benefit:benefit_desc_5')}/>
            </div>
            <div className="col-md-5 col-12">
                <BenefitItem icon={<LockIcon/>}
                             title={t('benefit:benefit_title_6')}
                             desc={t('benefit:benefit_desc_6')}/>
            </div>
            <div className="col-md-5 col-12">
                <BenefitItem icon={<LearnIcon/>}
                             title={t('benefit:benefit_title_7')}
                             desc={t('benefit:benefit_desc_7')}/>
            </div>
            <div className="col-md-5 col-12">
                <BenefitItem icon={<LearnIcon/>}
                             title={t('benefit:benefit_title_8')}
                             desc={t('benefit:benefit_desc_8')}/>
            </div>
            <div className="col-md-5 col-12">
                <BenefitItem icon={<FoodIcon/>}
                             title={t('benefit:benefit_title_9')}
                             desc={t('benefit:benefit_desc_9')}/>
            </div>
            <div className="col-md-5 col-12">
                <BenefitItem icon={<LockIcon/>}
                             title={t('benefit:benefit_title_10')}
                             desc={t('benefit:benefit_desc_10')}/>
            </div>
        </div>

        <div className="row justify-content-center mt-5">
            <div className="col-md-10 col-12">
                <table className="table mt-5">
                    <thead>
                    <tr>
                        <th scope="col-3"/>
                        <th scope="col">{t("benefit:one_plus")}</th>
                        <th scope="col">{t("benefit:five_plus")}</th>
                        <th scope="col">{t("benefit:gold_nft")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th scope="row">{t('benefit:benefit_title_1')}</th>
                        <td><DoneIcon/></td>
                        <td><DoneIcon/></td>
                        <td><DoneIcon/></td>
                    </tr>
                    <tr>
                        <th scope="row">{t('benefit:benefit_title_2')}</th>
                        <td><DoneIcon/></td>
                        <td><DoneIcon/></td>
                        <td><DoneIcon/></td>
                    </tr>
                    <tr>
                        <th scope="row">{t('benefit:benefit_title_3')}</th>
                        <td><DoneIcon/></td>
                        <td><DoneIcon/></td>
                        <td><DoneIcon/></td>
                    </tr>
                    <tr>
                        <th scope="row">{t('benefit:benefit_title_6')}</th>
                        <td><DoneIcon/></td>
                        <td><DoneIcon/></td>
                        <td><DoneIcon/></td>
                    </tr>
                    <tr>
                        <th scope="row">{t('benefit:benefit_title_4')}</th>
                        <td/>
                        <td><DoneIcon/></td>
                        <td><DoneIcon/></td>
                    </tr>
                    <tr>
                        <th scope="row">{t('benefit:benefit_title_5')}</th>
                        <td/>
                        <td><DoneIcon/></td>
                        <td><DoneIcon/></td>
                    </tr>
                    <tr>
                        <th scope="row">{t('benefit:benefit_title_7')}</th>
                        <td/>
                        <td><DoneIcon/></td>
                        <td><DoneIcon/></td>
                    </tr>
                    <tr>
                        <th scope="row"> {t('benefit:benefit_title_8')} </th>
                        <td/>
                        <td><DoneIcon/></td>
                        <td><DoneIcon/></td>
                    </tr>
                    <tr>
                        <th scope="row">  {t('benefit:benefit_title_9')}</th>
                        <td/>
                        <td/>
                        <td><DoneIcon/></td>
                    </tr>
                    <tr>
                        <th scope="row"> {t('benefit:benefit_title_10')}  </th>
                        <td/>
                        <td/>
                        <td><DoneIcon/></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}

export default Benefit;
