import { createSelector, createSlice } from '@reduxjs/toolkit'
import {getMetaMask, isMetaMaskInstalled} from "../../helpers/metamask";

const sliceName = 'app'

const appSlice = createSlice({
    name: sliceName,
    initialState: {
        theme: 'light',
        banner: {
            message: null,
            title: null,
            variant: 'fail',
            visible: false,
        },
        isSubscriptionModalOpen: false,
        isUnsubscribeModalOpen: false,
        subscriptionModalUser: null,
        isMetaMaskInstalled: isMetaMaskInstalled(),
        walletAddress: null,
        isEthChainSelected: false,
    },
    reducers: {
        setAlertMessage: (state, action) => {
            state.banner.message = action.payload.message
            state.banner.title = action.payload.title
            state.banner.variant = action.payload.variant ?? 'fail'
            state.banner.visible = true
        },
        toggleAlert: (state, action) => {
            state.banner.visible = action.payload.visible
        },
        setWalletAddress: (state, action) => {
            state.walletAddress = action.payload
        },
        setIsMetaMaskInstalled: (state, action) => {
            state.isMetaMaskInstalled = action.payload
        },
        setIsEthChainSelected: (state, action) => {
            state.isEthChainSelected = action.payload
        },
    },
})

// Selectors
const selectSelf = (state) => state[sliceName]
export const selectTheme = createSelector(selectSelf, (state) => state.theme)
export const selectAlertMessage = createSelector(
    selectSelf,
    (state) => state?.banner
)
export const selectWalletAddress = createSelector(
    selectSelf,
    (state) => state?.walletAddress
)
export const selectIsMetaMaskInstalled = createSelector(
    selectSelf,
    (state) => state?.isMetaMaskInstalled
)
export const selectIsEthChainSelected = createSelector(
    selectSelf,
    (state) => state?.isEthChainSelected
)
export const {
    setAlertMessage,
    toggleAlert,
    setWalletAddress,
    setIsMetaMaskInstalled,
    setIsEthChainSelected
} = appSlice.actions

export default appSlice.reducer
