import React, {useEffect, useState} from 'react';
import {KrominLogo, WalletIcon} from "../../assets/icons/icons";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    selectIsMetaMaskInstalled,
    selectWalletAddress,
    setAlertMessage, setIsEthChainSelected,
    setWalletAddress
} from "../../store/slices/app";
import {getAvailableAccounts, getCurrentChain, getMetaMask, metamaskInitialise} from "../../helpers/metamask";
import {useTranslation} from "react-i18next";

const Header = ({isHome = true}) => {
    const dispatch = useDispatch();
    const {t,i18n} = useTranslation()
    const [showDisconnectButton, setShowDisconnectButton] = useState(false);
    const walletAddress = useSelector(selectWalletAddress)
    const isMetaMaskInstalled = useSelector(selectIsMetaMaskInstalled)


    useEffect(() => {
        if(walletAddress){
            dispatch(setAlertMessage({title:"Success", message: t("header:success"), variant:"success"}))
        }

    },[walletAddress])

    useEffect(
        () => {
            //event bindings
            if(isMetaMaskInstalled){
                const metamaskInstance = getMetaMask()
                //handle change and disconnect
                metamaskInstance.on('accountsChanged', handleAccountsChanged);
                metamaskInstance.on('chainChanged', handleChainChange);
                //handleWalletConnection()
            }
            return () => {
                if(isMetaMaskInstalled){
                    const metamaskInstance = getMetaMask()
                    metamaskInstance.removeListener('accountsChanged', handleAccountsChanged);
                    metamaskInstance.removeListener('chainChanged', handleChainChange);
                }
            }
        },
        [isMetaMaskInstalled]
    )

    const handleAccountsChanged = (accounts) => {
        if(accounts.length === 0){
            walletDisconnect()
        }else{
            if(accounts[0] !== walletAddress){
                dispatch(setWalletAddress(accounts[0]))
                localStorage.setItem("wallet", accounts[0])
            }
        }
    }
    const handleChainChange = (chainId) => {
        dispatch(setIsEthChainSelected(chainId === '0x1')) // 0x1 === ETH
        // Handle the new chain.
        //window.location.reload();
    }

    const handleWalletConnection = async () => {
        try{
            const previousAccount = localStorage.getItem("wallet")
            if(previousAccount){
                dispatch(setWalletAddress(previousAccount))
            }else{
                const metamaskAccounts = await getAvailableAccounts()
                handleAccountsChanged(metamaskAccounts)
            }
            dispatch(setAlertMessage({title:"Success", message:t("header:success"), variant:"success"}))
            const currentChain = await getCurrentChain()
            handleChainChange(currentChain)
        }catch (e){
            dispatch(setAlertMessage({title:"Error", message: e.message}))
        }
    }

    // Function for getting handling all events
    const walletDisconnect = async () => {
        //await disconnectMetamask()
        localStorage.removeItem("wallet")
        dispatch(setWalletAddress(null))
        dispatch(setAlertMessage({message: t("header:disconnected"), title:"Error", variant:"fail"}))
        setShowDisconnectButton(false)
    };

    const changeLanguageHandler = (lang) => {
        i18n.changeLanguage(lang)
        localStorage.setItem("i18nextLng",lang)
    }

    return <div className="header">
        <div className={"mobile-menu d-md-none d-flex"}>
            <a href="#about"> About </a>
            <a href="#benefit"> Utilities </a>
            <a href="#dao"> Dao </a>
            <a href="#faq"> FAQ </a>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-md-3 col-7">
                    <Link to={"/"}><KrominLogo/></Link>
                </div>
                <div className="col-md-6 d-flex align-items-center justify-content-between d-md-flex d-none">
                    {isHome && <>
                        <a href="#about"> About </a>
                        <a href="#benefit"> Utilities </a>
                        <a href="#dao"> Dao </a>
                        <a href="#faq"> FAQ </a>
                        <div className={"languages"}>
                            <img src="https://flagcdn.com/16x12/it.png" width="16" height="12" alt="Italian" onClick={() => changeLanguageHandler("it")}/>
                            <img src="https://flagcdn.com/16x12/gb.png" width="16" height="12" alt="English" onClick={() => changeLanguageHandler("en")}/>
                        </div>
                    </>}
                </div>
                <div className="col-md-3  col-5 d-flex align-items-center justify-content-end position-relative">
                    {!showDisconnectButton && <button className={"primary-btn"}
                             onClick={() => walletAddress ? setShowDisconnectButton(!showDisconnectButton) : handleWalletConnection()}>
                        <WalletIcon/>
                        {walletAddress ? walletAddress : t('header:connect')}
                    </button>}
                    {showDisconnectButton && <div className="disconnect-wallet ">
                        <button className="btn btn-danger" onClick={walletDisconnect}>{t('header:disconnect')}</button>
                    </div>}
                </div>
            </div>
        </div>
    </div>
}

export default Header;
