import {ethers} from "ethers";

export function isMetaMaskInstalled() {
    return Boolean(window.ethereum && window.ethereum.isMetaMask);
}

export const getMetaMask = () => {
    if(isMetaMaskInstalled()){
        if(window.ethereum.providers){
            return  window.ethereum.providers.find((provider) => provider.isMetaMask);
        }else{
            return window.ethereum
        }
    }
    return null;
}

export const isMetaMaskConnected = () =>  {
    const metamaskProvider = getMetaMask()
    let isConnected = false;
    if(metamaskProvider){
        isConnected = metamaskProvider.isConnected()
    }
    return isConnected
}

export const getCurrentChain = async () => {
    const metamaskProvider = getMetaMask()
    try {
        return await metamaskProvider.request({ method: 'eth_chainId' });

    }catch (err){
        throw err;
    }
}


export async function getAvailableAccounts() {
    try{
        const metamaskProvider = getMetaMask()
        let accounts = []
        if(metamaskProvider){
            accounts = await metamaskProvider.request({method: 'eth_requestAccounts'});
        }
        return accounts
    }catch (err){
        if (err.code === 4001) {
            // EIP-1193 userRejectedRequest error
            // If this happens, the user rejected the connection request.
            throw new Error('Please connect to MetaMask.')
        } else {
            throw err;
        }
    }
}

export async function getWalletBalance(walletAddress) {
    try{
        const metamaskProvider = getMetaMask()
        let balance = 0;
        if(metamaskProvider){
            balance = await metamaskProvider.request({
                method: 'eth_getBalance',
                params: [walletAddress, 'latest']
            })
        }
        return ethers.utils.formatEther(balance)
    }catch (err){
        console.log(err)
    }
}

export const metamaskInitialise = async () => {
    const installed = isMetaMaskInstalled();
    if(!installed){
        throw new Error('Please install MetaMask to continue.');
    }
    const connectedAccount = await isMetaMaskConnected();
    //const isConnectedToChain = isChainConnected()
    return {connected: connectedAccount ,installed}
}


