import React from "react";
import Accordion from "../../../components/accordion/Accordion";
import {useTranslation} from "react-i18next";


const Faq = () => {
    const {t} = useTranslation()
    const faqs = [
        {title: t("faq:faq_title_1"), desc:t("faq:faq_message_1")},
        {title: t("faq:faq_title_2"), desc:t("faq:faq_message_2")},
        {title: t("faq:faq_title_3"), desc:t("faq:faq_message_3")},
        {title: t("faq:faq_title_4"), desc:t("faq:faq_message_4")},
        {title: t("faq:faq_title_5"), desc:t("faq:faq_message_5")},
        {title: t("faq:faq_title_6"), desc:t("faq:faq_message_6")},
        {title: t("faq:faq_title_7"), desc:t("faq:faq_message_7")},
    ]

    return <div className="row" id="faq">
        <div className="col-12">
            <h2><b>Faq</b></h2>
            <div className="dash"/>
        </div>

        <div className="col-12 mt-5">
            {faqs.map((item,index) => {
                return  <Accordion desc={item.desc} title={item.title} key={index}/>
            })}
        </div>
    </div>
}

export default Faq;
