
const getComponentStyle = () => ({
    root: {
        zIndex: 13,
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    child: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '32px',
        textAlign: 'center',
        color: '#fff'
    }
})

const Overlay = ({children}) => {
    const componentStyle = getComponentStyle()
    return <div style={componentStyle.root}>
        <div style={componentStyle.child}>{children}</div>
    </div>
}

export default Overlay;