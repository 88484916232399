import React from "react";
import {useTranslation} from "react-i18next";

const Footer = () => {
    const {t,i18n} = useTranslation()

    const changeLanguageHandler = (lang) => {
        i18n.changeLanguage(lang)
        localStorage.setItem("i18nextLng",lang)
    }

    return <footer >
       <p>{t("footer:message")} {new Date().getFullYear()}</p>
        <div className={"languages d-md-none d-flex"}>
            <img src="https://flagcdn.com/16x12/it.png" width="16" height="12" alt="Italian" onClick={() => changeLanguageHandler("it")}/>
            <img src="https://flagcdn.com/16x12/gb.png" width="16" height="12" alt="English" onClick={() => changeLanguageHandler("en")}/>
        </div>
    </footer>
}

export default Footer;
